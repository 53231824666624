import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
// import { Context as AppContext } from "../context/appContext";
import axios from "axios";
import { Container, Row, Col, Form, Button, Table, Card } from "react-bootstrap";
import PaymentResult from "./PaymentResult";
import PaymentFormSDK from "./PaymentFormSDK";


const MakePayment = () => {
  const { invoiceId } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  //   const { state } = useContext(AppContext);
  const [invoice, setInvoice] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [company, setCompany] = useState(null);
  const [publicKey, setPublicKey] = useState(null);
  
  const [error, setError] = useState(null);

  const getInvoice = async () => {
    try {
      const response = await axios.get(`/api/invoices/${invoiceId}`);
      console.log("response", response.data);
      if (response.data.error) {
        console.log("error", response.data.error);
        setError(response.data.error.message);
        setInvoice(null);
        setCustomer(null);
        setCompany(null);
      } else {
        console.log("invoice data found");
        setInvoice(response.data.invoice);
        setCustomer(response.data.customer);
        setCompany(response.data.company);
        setPublicKey(response.data.publicKey);
      }
    } catch (error) {
      console.error("Error getting invoice for payment:", error);
    }
  };

  useEffect(() => {
    console.log("invoiceId", invoiceId);
    if (invoiceId) {
      getInvoice();
    }
  }, []);

  if (error) {
    return (
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <div className="alert alert-danger" role="alert">
              {error}
            </div>
            <Button variant="secondary" onClick={() => setError(null)}>
              Clear Message
            </Button>
          </Col>
        </Row>
      </Container>
    );
  }

  if (!invoice) {
    return (
      <Container className="mt-3">
        <Row className="justify-content-center">
          <Col xs={12} md={8} lg={6}>
            <div className="alert alert-danger" role="alert">
              No invoice found
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col md={8}>
          <h2>Invoice {invoice.invoice_number}</h2>
          <div className="mb-4">
            <p>
              Bill to: {customer.first_name} {customer.last_name}
              <br /> {company.name} <br />
              {company.address}, <br />
              {company.city}, {company.state} {company.zip}
            </p>
          </div>
          <Table bordered>
            <tbody>
              <tr>
                <td>Invoice number</td>
                <td>{invoice.invoice_number}</td>
              </tr>
              <tr>
                <td>Invoice Amount</td>
                <td>${invoice.amount_due}</td>
              </tr>
              <tr>
                <td>Payment Due Date</td>
                <td>{new Date(invoice.due_date).toLocaleDateString()}</td>
              </tr>
            </tbody>
          </Table>
          {/* <Table striped bordered>
            <thead>
              <tr>
                <th>Products & Services</th>
                <th>Qty</th>
                <th>Unit Price</th>
                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {invoice.line_items.map((item, index) => (
                <tr key={index}>
                  <td>{item.name}</td>
                  <td>{item.quantity}</td>
                  <td>${item.unit_price.toFixed(2)}</td>
                  <td>${item.amount.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </Table> */}
          <div className="text-right">
            {/* <p>Subtotal: ${invoice.subtotal.toFixed(2)}</p>
            <p>Discount: (${invoice.discount.toFixed(2)})</p>
            <p>Setup fee: ${invoice.setup_fee.toFixed(2)}</p>
            <p>Extra tax: ${invoice.extra_tax.toFixed(2)}</p>
            <h4>Total: ${invoice.total.toFixed(2)}</h4> */}
          </div>
          {isLoading ? (
            <Card className="p-4 mt-3">
              <Card.Body>Loading...</Card.Body>
            </Card>
          ) : (
            <PaymentFormSDK publicKey={publicKey} />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MakePayment;
