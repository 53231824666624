import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import { Context as AppContext } from "../context/appContext";

const OpenInvoices = () => {
  const { state, getOpenInvoices, checkAuthCookie } = useContext(AppContext);

  useEffect(() => {
    checkAuthCookie();
    getOpenInvoices();
    console.log(state);
    if (state.openInvoices) {
      // console.log(state.openInvoices);
    }
  }, []);

  const emailPaymentLink = (invoiceId) => {
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: `/api/invoices/sendPaymentLink/${invoiceId}`,
      headers: {
        Authorization: `Bearer ${state.authToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        alert("Payment link sent");
      })
      .catch((error) => {
        console.log(error);
        alert("Error sending payment link");
      });
  };

  const emailReceipt = (invoiceId) => {
    let config = {
      method: "post",
      url: `/api/invoices/sendPaymentSuccessEmail/${invoiceId}`,
      headers: {
        Authorization: `Bearer ${state.authToken}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        alert("Payment success email sent");
      })
      .catch((error) => {
        console.log(error);
        alert("Error sending payment success email");
      });
  };

  return (
    <div>
      <h2>Open Invoices</h2>
      <table className="table">
        <thead>
          <tr>
            <th>Action</th>
            <th>Invoice ID</th>
            <th>Company</th>
            <th>Customer</th>
            <th>Amount</th>
            <th>Due Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {state.openInvoices &&
            state.openInvoices.map((invoice) => (
              <tr key={invoice.id}>
                <td>
                  {invoice.status === "paid" ? (
                    <button
                      className="btn btn-secondary"
                      onClick={() => emailReceipt(invoice.id)}
                    >
                      Email Receipt
                    </button>
                  ) : (
                    <button
                      className="btn btn-primary"
                      onClick={() => emailPaymentLink(invoice.id)}
                    >
                      Email Payment Link
                    </button>
                  )}
                </td>
                <td>{invoice.invoice_number}</td>
                <td>{invoice.company.name}</td>
                <td>
                  {invoice.customer.first_name} {invoice.customer.last_name}
                </td>
                <td>${invoice.amount_due}</td>
                <td>{new Date(invoice.due_date).toLocaleDateString()}</td>
                <td>{invoice.status}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default OpenInvoices;
