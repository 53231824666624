import React, { useEffect, useState, useRef } from "react";
import SignatureCanvas from "react-signature-canvas";
import "./PaymentFormSDK.css";
const PaymentFormSDK = ({ publicKey, onPaymentSuccess }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);

  const [signature, setSignature] = useState(null);
  const sigPad = useRef({});

  useEffect(() => {
    loadSDK();
  }, [publicKey]);

  const loadSDK = async () => {
    if (
      !document.querySelector(
        'script[src="https://gateway-sb.clearent.net/js-sdk/js/clearent-host.js"]'
      )
    ) {
      try {
        await loadScript(
          "https://gateway-sb.clearent.net/js-sdk/js/clearent-host.js"
        );
        initializeClearentSDK();
      } catch (err) {
        setError("Failed to load Clearent SDK");
        console.error(err);
      }
    } else {
      initializeClearentSDK();
    }
  };

  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      script.onload = resolve;
      script.onerror = reject;
      document.body.appendChild(script);
    });
  };

  const initializeClearentSDK = () => {
    window.ClearentSDK?.init({
      baseUrl: "https://gateway-sb.clearent.net",
      pk: publicKey,
    });
  };

  const clearSignature = () => {
    if (sigPad.current) {
      sigPad.current.clear();
    }
    setSignature(null);
  };

  const saveSignature = () => {
    if (sigPad.current.isEmpty()) {
      return; // Don't save empty signatures
    }
    setSignature(sigPad.current.getTrimmedCanvas().toDataURL("image/png"));
  };

  const processPayment = () => {
    return new Promise((resolve, reject) => {
      // Set up one-time handlers for this specific payment attempt
      window.ClearentValidation = (messages) => {
        if (messages && messages.length > 0) {
          reject({ type: "validation", messages });
        }
      };

      window.ClearentTokenSuccess = (raw, json) => {
        resolve({ raw, json });
      };

      window.ClearentTokenError = (raw, json) => {
        reject({ type: "error", raw, json });
      };

      // Trigger the payment token request
      window.ClearentSDK?.getPaymentToken();
    });
  };

  const handlePaymentSubmit = async () => {
    setIsProcessing(true);
    setError(null);

    try {
      const result = await processPayment();
      console.log("Payment processed successfully:", result);
      onPaymentSuccess?.(result); // Call the success callback if provided
    } catch (err) {
      console.error("Payment processing failed:", err);
      if (err.type === "validation") {
        setError(`Validation error: ${err.messages.join(", ")}`);
      } else {
        setError("Payment processing failed. Please try again.");
      }
    } finally {
      setIsProcessing(false);
    }
  };

  return (
    <div className="paymentForm">
      <div className="container">
        <label id="shipping-label">Shipping Information</label>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-6">
            <div className="input-group">
              <input
                placeholder="First Name"
                autoComplete="off"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-6">
            <div className="input-group">
              <input
                placeholder="Last Name"
                autoComplete="off"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-sm-12">
            <div className="input-group">
              <input
                placeholder="Address"
                type="text"
                className="form-control"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12 col-sm-12">
            <div className="input-group">
              <input placeholder="City" type="text" className="form-control" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 col-sm-6">
            <div className="input-group">
              <input
                placeholder="State"
                autoComplete="off"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
          <div className="col-12 col-md-6 col-sm-6">
            <div className="input-group">
              <input
                placeholder="Zip"
                autoComplete="off"
                type="text"
                className="form-control"
                required
              />
            </div>
          </div>
        </div>
        {error && (
          <div className="alert alert-danger" role="alert">
            {error}
          </div>
        )}
        <label id="payment-form-label" htmlFor="payment-form">
          Payment details
        </label>
        <div id="payment-form" />

        <div className="signature-section">
          {signature ? (
            <div className="saved-signature">
              <img
                src={signature}
                alt="Signature"
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  border: "1px solid #ccc",
                  borderRadius: "4px",
                  marginBottom: "10px",
                }}
              />
              <button
                onClick={clearSignature}
                className="btn btn-secondary"
                style={{ marginLeft: "10px" }}
              >
                Clear Signature
              </button>
            </div>
          ) : (
            <div className="signature-pad-container">
              <SignatureCanvas
                ref={sigPad}
                canvasProps={{
                  className: "signature-canvas",
                  style: {
                    width: "100%",
                    height: "150px",
                    border: "1px solid #ccc",
                    borderRadius: "4px",
                    backgroundColor: "#fff",
                  },
                }}
              />
              <div className="signature-buttons" style={{ marginTop: "10px" }}>
                <button
                  onClick={clearSignature}
                  className="btn btn-secondary"
                  style={{ marginRight: "10px" }}
                >
                  Clear
                </button>
                <button onClick={saveSignature} className="btn btn-primary">
                  Save Signature
                </button>
              </div>
            </div>
          )}
        </div>

        <button
          id="payment-button"
          type="button"
          className="btn btn-success"
          onClick={handlePaymentSubmit}
          disabled={isProcessing}
        >
          {isProcessing ? "Processing..." : "Submit Payment"}
        </button>
      </div>
    </div>
  );
};

export default PaymentFormSDK;
